@import 'components/global.scss';

.accordion {
    
    button {

        width: 100%;
        padding: $scale-2 $scale-3;
        font-size: $scale;
        font-family: 'Source Sans Pro', 'sans-serif' !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {

            background-color: darken(white, 3%);
        }
    }

    .content {
        padding: $scale-2;
    }
}