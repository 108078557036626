.tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    right: -75%;
    top: calc((100% + 20px) * -1);
    display: none;

    &.visible {
        display: block;

        &+.arrow {
            position: absolute;
            top: -10px;
            left: 50%;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
            transform: translateX(-55%) rotate(180deg);
        }
    }
}