@import 'components/global.scss';

fieldset {

  position: relative;
  clear: both;
  border: none;
  padding: 0;
  margin: 0 0 $scale-3 0;


  label {

    text-transform: none;

  }
}

.error {

  border: none;
  padding: 0 $scale $scale2;

  &:before {

    // psuedo border
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: $scale2;
    border-radius: $radius;
    border: 1px solid $c-red;
    background-color: lighten($c-red, 38%);

  }

  legend {

    color: $c-red;
    z-index: 2;
    margin-left: -$scale1;
    padding-top: $scale;

  }

  > section:first-of-type {

    margin-top: $scale1;

  }
}

.errorMessage {

  top: 0;
  clear: both;
  margin-left: -1.1em;
  margin-right: -1.1em;
  margin-bottom: 0;

}

.inset {

  margin-left: $scale5;
  
}