@import 'components/global.scss';

.tabs {

    display: flex;
    justify-content: space-evenly;
    margin-bottom: $scale;
    box-shadow: 0px 4px 6px -7px;

    .tab {

        flex-grow: 1;
        flex-shrink: 0;
        text-align: center;
        padding-bottom: $scale;
        color: lighten($c-text, 30%);

        &.active {
            border-bottom: 3px solid $c-purple;
            color: $c-text;
        }

        &:hover {
            cursor: pointer;
        }
    }
}