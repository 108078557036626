@import 'components/global.scss';

.input {

  position: relative;

  &.copy {
    
    display: flex;
    gap: $scale-1;
    align-items: center;

    button {

      margin-bottom: $scale1;

    }
  }
}

.textbox {

  display: block;
  position: relative;
  width: 100%;
  color: $c-text;
  font-size: $scale;
  padding: $scale;
  margin: 0 0 $scale1;
  border-radius: $radius;
  background-color: white;
  border: 1px solid $c-border;
  -webkit-appearance: none;
  -webkit-border-radius: $radius;

  &:focus {

    background-color: #fafafa;

  }

  &:disabled {

    opacity: 0.5;

  }
}

textarea.textbox {

  font-family: 'Source Sans Pro', helvetica, arial, sans-serif;
  resize: vertical;

  &:not(.unsetHeight) {

    min-height: $scale10;
    
  }
}

.inputButton {

  margin-bottom: $scale1;
  margin-right: $scale1;

  + .successIcon {

    bottom: $scale8;

  }
}

.dateContainer {

  &.clearable {

    display: flex;
    align-items: center;

  }
  
  &.range.clearable {

    min-width: 245px;

  }

  .dateWrapper {
  
    width: 100%;
    max-width: $scale13;
    margin-bottom: $scale1;
    z-index: unset;
  
    &:last-of-type {
  
      margin-bottom: 0;
  
    }
  
    > div {
  
      margin-top: -$scale5;
      z-index: 3;
  
    }
  
    input {
  
      margin-bottom: 0;
  
    }
  
    span {
  
      width: 2em !important;
  
    }
  
    + button {
  
      margin-left: -4.25rem;
      padding: $scale1;
      z-index: 1;
  
    }
  
    &.open + button {
  
      z-index: 0;
  
    }
  }
}

.success {

  background-size: 0.85em;
  border-color: lighten($c-green, 24%);
  background-position: center right 1em;

}

.successIcon {

  position: absolute;
  bottom: 1.1em;
  right: $scale-2;

}

.error {

  position: relative;
  color: $c-red;
  margin-bottom: 0;
  border: 1px solid $c-red;
  background-color: lighten($c-red, 38%);

}

.warning {

  background: darken($c-orange, 3%);

}


// placeholders
::-webkit-input-placeholder {

  color: lighten($c-text, 24%);

}

::-moz-placeholder {

  color: lighten($c-text, 24%);

}

:-ms-input-placeholder {

  color: lighten($c-text, 24%);

}

:-moz-placeholder {

  color: lighten($c-text, 24%);

}

.error::-webkit-input-placeholder {

  color: $c-red;

}

.error::-moz-placeholder {

  color: $c-red;

}

.error:-ms-input-placeholder {

  color: $c-red;

}

.error:-moz-placeholder {

  color: $c-red;

}