@import 'components/global.scss';

.btn {

  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  color: white;
  font-size: $scale;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  padding: $scale $scale4;
  border-radius: $radius;
  font-family: 'Source Sans Pro', 'sans-serif' !important;
  background-color: $c-purple;
  transition: all 0.4s ease-in-out;

  &:hover {

    background-color: darken($c-purple, 6%);
    transition: all 0.4s ease-in-out;

  }

  &.alignButtonLeft {
    display: block;
  }

  &.alignButtonRight {
    display: block;
    margin-left: auto;
  }

  &.mb-2 {
    margin-bottom: $scale2;
  }

  &.mb-3 {
    margin-bottom: $scale3;
  }
}

.red {

  background-color: $c-red;

  &:hover {

    background-color: darken($c-red, 5%) !important;
    border-color: darken($c-red, 5%) !important;

  }
}

.blue {

  background-color: $c-blue;

  &:hover {

    background-color: darken($c-blue, 5%) !important;
    border-color: darken($c-blue, 5%) !important;

  }
}

.green {

  background-color: $c-green;

  &:hover {

    background-color: darken($c-green, 5%) !important;
    border-color: darken($c-green, 5%) !important;

  }
}

.purple {

  background-color: $c-purple;

  &:hover {

    background-color: darken($c-purple, 5%) !important;
    border-color: darken($c-purple, 5%) !important;

  }
}

.darkPurple {

  background-color: $c-dark-purple;

  &:hover {

    background-color: darken($c-dark-purple, 5%) !important;
    border-color: darken($c-dark-purple, 5%) !important;

  }
}

.small {

  font-size: 0.9em;
  padding: 0.8em $scale2;

}

.big {

  @media (min-width: $mobile) {

    font-size: $scale1;

  }
}

.loader {

  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: $radius;

  &:after {

    display: none;
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    right: 1em;
    top: 1.05em;
    z-index: 3;
    background-size: contain;
    background-image: url("./icons/ico-loader.svg");

  }

  input,
  button {

    display: block;
    width: 100% !important;
    cursor: pointer;

  }
}

.loading {

  &:after {

    // loading spinner
    display: block;
    transform-origin: center center;
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;

  }
}

.outline {
  
  color: $c-text;
  background: transparent;
  border: 1px solid $c-border-dark;
  
  &:hover {
    
    color: white;
    border-color: $c-red;
    background: $c-red;
    
  }
}

.text {

  cursor: pointer;
  color: $c-text;

}

.icon {

  display: inline-block;
  padding: 0;
  width: $scale1;
  height: $scale1;

  svg {

    width: 100%;
    height: 100%;

  }

  &.copy {

    padding: $scale-1;
    width: $scale5;
    height: $scale5;

  }
}

.iconText {

  position: relative;
  padding-left: $scale4 + $scale;

  svg {

    position: absolute;
    left: $scale1;
    top: 50%;
    width: $scale1;
    height: $scale1;
    transform: translateY(-50%);

  }

  span {

    display: block;
    line-height: 0.8em;

  }
}

.alignIconRight {

  padding-left: 0;
  padding-right: $scale2;

  svg {

    left: auto;
    right: 0;

  }
}

.alignIconLeft {

  padding-left: $scale2;
  padding-right: 0;

  svg {

    left: 0;
    right: auto;

  }
}

.fullWidth {

  width: 100%;

}

.halfWidth {

  float: left;
  width: 49%;

  &:last-child {

    float: right;

  }
}

.rounded {

  border-radius: $scale3;

}

.fillIcon svg {

  fill: white;

}

.dark {

  color: $c-text;

}

.mb-5 {

  margin-bottom: $scale2;

}

.center {

  display: block;
  margin: 0 auto;
  float: none !important;

}