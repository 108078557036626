@import 'components/global.scss';

.select {

  position: relative;
  cursor: pointer;
  color: $c-text;
  width: 100%;
  margin-bottom: $scale1;

  select:-moz-focusring {

    color: transparent;
    text-shadow: 0 0 0 #000;

  }

  select::-ms-expand {

    display: none;

  }

}

.success {

  border-color: lighten($c-green, 24%);

}

.error {

  border: none;

  &:before {

    // psuedo border
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: $scale2;
    border-radius: $radius;
    border: 1px solid $c-red;

  }

  &:after {

    margin-top: -$scale;
    bottom: unset;
    top: $scale4;

  }

  select {

    color: $c-red;
    z-index: 2;

  }
}

.warning {

  &:before {

    // psuedo border
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: $scale2;
    border-radius: $radius;
    border: 1px solid $c-orange;

  }

  select {

    color: $c-orange;
    z-index: 2;

  }
}

.message {

  margin-bottom: 0;

}