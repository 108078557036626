@import 'components/global.scss';

.card {

  position: relative;
  padding: $scale2;
  margin-bottom: $scale2;
  background: white;
  border-radius: $radius;

  &:last-of-type {

    margin-bottom: 0;

  }

  header {

    position: relative;
    overflow: hidden;
    text-align: left;
    color: $c-text;
    padding-bottom: $scale1;
    margin-bottom: $scale3;
    background-color: white;
    border-bottom: 1px dotted $c-border;

    h1 {

      float: left;
      margin: 0;
      font-size: $scale1;
      font-weight: 600;

    }

    a {

      float: right;
      margin-top: 0.2em;
      font-size: 0.9em;

    }

    &.flex {

      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media(max-width: 660px) {

        flex-direction: column;
        align-items: start;
        gap: $scale1;

      }
    }
  }

  table {

    min-height: $scale5;
    margin-top: -$scale1;
    margin-bottom: -$scale2;

    @media(max-width: $mobile) {

      margin-top: 0;

    }
  }

  >h2 {

    font-size: $scale;
    font-weight: 700;
    margin: $scale 0 $scale-2;

  }

  >svg {

    width: $scale1;

  }

  >a {

    display: block;
    font-weight: 600;
    margin: $scale1 0 0;

  }

  &.inline {
    display: inline-block;
    vertical-align: top;

    @media (min-width: $mobile) {

      &:not(.halfWidth) {

        margin-right: $scale4;

      }
    }
  }
}

.center {

  margin-left: auto;
  margin-right: auto;

}

.shadow {

  box-shadow: 0em 0.15em 0.4em rgba(0, 0, 0, 0.05);

}

.loading {

  min-height: $scale13;

}

.restrictWidth {

  @media (min-width: $mobile) {
    min-width: $scale14;
    max-width: $scale15;

  }
}

.noPadding {

  padding: 0;

}

.halfWidth {
  
  width: 100%;

  @media (min-width: 600px) {

    width: 49%;

    &:nth-of-type(2n+1) {

      margin-right: 2%;

    }
  }
}

.transparent {
  background: none;
}