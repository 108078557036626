@import 'components/global.scss';

.tagContainer {
    background-color: white;
    border: 1px solid $c-border;
    flex-grow: 1;
    border-radius: $radius;
    text-align: left;
    margin: 0 0 $scale1;
    color: $c-text;
    font-size: $scale;
    padding: $scale $scale 0;

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;
            padding: 2px;
            margin-right: 5px;
            overflow-wrap: break-word;
            word-break: break-word;

            input {
                border: none;
                width: 100%;
                border-radius: none;
            }

            &:last-of-type:not(li.inputContainer) {
                margin-bottom: $scale;
            }

            & + li.inputContainer {
                margin-top: $scale;
            }
        }
    }

    .tag {
        display: inline-flex;
        align-items: center;

        border: 1px solid $c-purple;
        border-radius: 5px;
        padding: 0.1rem 0.3rem;
        margin: 0.2rem;

        button {
            width: 1.2rem;
            height: 1.2rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.5rem;
            background-color: $c-dark-purple;
            color: white;
            border: none;
            border-radius: 50%;
            box-sizing: border-box;
            padding: 0;
        }
    }

    .inputContainer {
        width: 100%;
        margin-right: 0;

        input {
            color: $c-text;
            font-size: $scale;
            padding: $scale 0.5rem;

            &:focus {

                background-color: #fafafa;

            }
        }
    }


}

.inputDisabled {
    border: none;
    margin: 0;

    ul li {
        margin-bottom: 0 !important;
    }
}

.success {

    border-color: lighten($c-green, 24%);

}

.error {

    border: 1px solid $c-red;
    margin-bottom: 0;

}